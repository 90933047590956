import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/slices/user';

import { CircularProgress, TextField, Typography } from '@mui/material';

import { mapUserInfo } from 'components/config/profile';

const Profile = () => {
  const [data, setData] = useState({});
  const userInfo = useSelector(userSelector.getInfo);

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length) {
      setData(mapUserInfo(userInfo));
    }
  }, [userInfo]);

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <>
      <Typography variant="h5" component="h2" mb={1}>
        Profile
      </Typography>
      {Object.keys(data).map((item, index) => (
        <TextField
          key={index}
          style={{ width: '100%', margin: '10px 0' }}
          type="text"
          label={item}
          value={data[item]}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      ))}
    </>
  );
};

export default Profile;
