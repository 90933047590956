import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { useGetSystemListQuery } from 'store/api/hhs/recipe.api';
import {
  useCreateBalanceIncreaseMutation,
  useGetBalanceIncreaseTypesQuery,
} from 'store/api/hhsKeyCloack/balanceIncrease';

import { EditItem } from 'utils/EditItem';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import Columns from 'components/UI/Table/Columns';

import { types, walletTypes } from 'components/pages/BalanceIncrease/configs';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
};

const initialValues = {
  idempotency_key: '',
  user_id: '',
  partner_id: null,
  type: '',
  event_id: '',
  currency: null,
  amount: '',
  original_currency: null,
  original_amount: '',
  conversion_rate: '',
  wallet_type: '',
  user_message: '',
};

const Dialog = ({ handleClose }) => {
  const dispatch = useDispatch();

  const [itemsValue, setItemsValue] = useState(initialValues);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery({
      'type[]': 'agregator',
    });
  const { data: dataTypes, isLoading: typesLoading } =
    useGetBalanceIncreaseTypesQuery();
  const [createBalanceIncrease] = useCreateBalanceIncreaseMutation();

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleClose,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const submitData = useCallback(async () => {
    const result = await createBalanceIncrease({
      ...itemsValue,
      amount: parseFloat(itemsValue.amount),
      original_amount: parseFloat(itemsValue.original_amount),
      conversion_rate: parseFloat(itemsValue.conversion_rate),
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue]);

  const handleChange = useCallback(
    _.throttle(({ name, value }) => {
      editItemClass.handleChange({
        name,
        value,
        errors,
      });
    }, 500),
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    await editItemClass.handleSubmit({
      itemsValue,
      textFields: [
        'idempotency_key',
        'user_id',
        'partner_id',
        'type',
        'event_id',
        'currency',
        'original_currency',
        'wallet_type',
      ],
      numericFields: ['amount', 'original_amount', 'conversion_rate'],
    });
  }, [itemsValue]);

  useEffect(() => {
    if (submit) {
      submitData();
    }
  }, [submit]);

  return (
    <MuiDialog maxWidth="sm" fullWidth open={true}>
      <DialogTitle>Balance Increase request</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {process && <FullPageLoader />}
        <Box component="div" pt={1}>
          <Columns>
            <Text
              required
              fullWidth
              name="idempotency_key"
              label="Idempotency Key"
              onChange={(event) =>
                handleChange({
                  name: 'idempotency_key',
                  value: event.target.value,
                })
              }
              error={!!errors.includes('idempotency_key')}
              value={itemsValue.idempotency_key}
            />

            <Autocomplete
              label="System"
              name="partner_id"
              error={!!errors.includes('partner_id')}
              value={itemsValue.partner_id}
              handleChange={handleChange}
              options={dataSystems}
              loading={systemsLoading}
            />

            <Text
              required
              fullWidth
              label="User Id"
              name="user_id"
              onChange={(event) =>
                handleChange({ name: 'user_id', value: event.target.value })
              }
              error={!!errors.includes('user_id')}
              value={itemsValue.user_id}
            />

            <Text
              required
              fullWidth
              label="Event Id"
              name="event_id"
              onChange={(event) =>
                handleChange({ name: 'event_id', value: event.target.value })
              }
              error={!!errors.includes('event_id')}
              value={itemsValue.event_id}
            />

            <Select
              label="Type"
              name="type"
              error={!!errors.includes('type')}
              value={itemsValue['type']}
              handleChange={handleChange}
              options={dataTypes || types}
              loading={typesLoading}
            />

            <Select
              label="Wallet Type"
              name="wallet_type"
              error={!!errors.includes('wallet_type')}
              value={itemsValue['wallet_type']}
              handleChange={handleChange}
              options={walletTypes}
            />

            <Autocomplete
              label="Currency"
              name="currency"
              error={!!errors.includes('currency')}
              value={itemsValue.currency}
              handleChange={handleChange}
              options={dataCurrency}
              loading={currencyLoading}
            />

            <Text
              required
              fullWidth
              label="Amount"
              name="amount"
              onChange={(event) =>
                handleChange({ name: 'amount', value: event.target.value })
              }
              error={!!errors.includes('amount')}
              value={itemsValue.amount}
            />

            <Autocomplete
              label="Original Currency"
              name="original_currency"
              error={!!errors.includes('original_currency')}
              value={itemsValue.original_currency}
              handleChange={handleChange}
              options={dataCurrency}
              loading={currencyLoading}
            />

            <Text
              required
              fullWidth
              label="Original Amount"
              name="original_amount"
              onChange={(event) =>
                handleChange({
                  name: 'original_amount',
                  value: event.target.value,
                })
              }
              error={!!errors.includes('original_amount')}
              value={itemsValue.original_amount}
            />

            <Text
              required
              fullWidth
              label="Conversion Rate"
              name="conversion_rate"
              onChange={(event) =>
                handleChange({
                  name: 'conversion_rate',
                  value: event.target.value,
                })
              }
              error={!!errors.includes('conversion_rate')}
              value={itemsValue.conversion_rate}
            />

            <Text
              fullWidth
              label="User Message"
              name="user_message"
              onChange={(event) =>
                handleChange({
                  name: 'user_message',
                  value: event.target.value,
                })
              }
              value={itemsValue.user_message}
            />
          </Columns>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Send
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = propTypes;
export default Dialog;
