import React from 'react';

import PropTypes from 'prop-types';

import { Delete } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  filter: PropTypes.shape({}),
};

const ClearBtn = ({ handleClick, filter }) => {
  if (
    filter === undefined ||
    (Object.keys(filter).length > 0 &&
      Object.keys(filter).some((key) => filter[key].length > 0))
  ) {
    return (
      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton aria-label="delete" onClick={handleClick}>
          <Delete />
        </IconButton>
      </Box>
    );
  }

  return null;
};

ClearBtn.propTypes = propTypes;
export default ClearBtn;
