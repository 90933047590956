import dayjs from 'dayjs';

import { MS_IN_DAY } from 'config/dates';

export const startOfTheDay = dayjs().startOf('day').toDate();

export const getEventDurationInMs = (date, startOfTheDay) =>
  Math.abs(new Date(date).getTime() - startOfTheDay.getTime()) % MS_IN_DAY;

// ISO 8601 formats
export const convertMsDurationToISO = (ms) => dayjs.duration(ms).toISOString();

export const convertISODurationToTime = (isoString, unit = 'ms') => {
  const durationObj = dayjs.duration(isoString);

  switch (unit) {
    case 'hh':
      return durationObj.asHours();
    case 'mm':
      return durationObj.asMinutes();
    case 'ss':
      return durationObj.asSeconds();
    case 'ms':
      return durationObj.asMilliseconds();
    default:
      return null;
  }
};
