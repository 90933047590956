import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';

import { useGetReportPlayersQuery } from 'store/api/api/bingoService/bingoReports';

import { Box, TextField, Typography } from '@mui/material';

import Text from 'components/UI/Form/Text';
import Table from 'components/UI/Table/Table';

import { columns } from 'components/config/bingoPlayersReport';

const PlayersReports = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsRoundId = searchParams.get('round_id');
  const [params, setParams] = useState({
    page: 0,
    size: 25,
    round_id: paramsRoundId || '',
  });

  const { data } = useGetReportPlayersQuery(params, {
    skip: !paramsRoundId,
  });

  useEffect(() => {
    if (!paramsRoundId) {
      searchParams.delete('round_id');
      setSearchParams(searchParams);
    }
  }, [paramsRoundId]);

  const onChangeRoundId = useCallback(
    (event) => {
      setParams((prev) => ({ ...prev, round_id: event.target.value }));
      setSearchParams({ round_id: event.target.value });
    },
    [params],
  );

  return (
    <Box>
      <Typography variant="h5" component="h2">
        Players report
      </Typography>

      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          mt: 2,
        }}
      >
        <Text
          name="id"
          label="Bingo Round ID"
          onChange={(event) => onChangeRoundId(event)}
          value={params.round_id}
        />

        {data && (
          <Box sx={{ display: 'flex', columnGap: 2 }}>
            <TextField
              style={{ width: '100%' }}
              type="text"
              label="Rooms IDs:"
              value={data.roomId ?? '-'}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              disabled={true}
            />
            <TextField
              style={{ width: '100%' }}
              type="text"
              label="Playing Date/Time:"
              value={dayjs(data.playingDateTime).format('MMM D, YYYY h:mm A')}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              disabled={true}
            />
          </Box>
        )}
      </Box>
      {params.round_id && data && (
        <Table
          columns={columns}
          getDataQuery={useGetReportPlayersQuery}
          queryConditional={{ round_id: params.round_id }}
        />
      )}
    </Box>
  );
};

export default PlayersReports;
