import _ from 'lodash';

import { showAlert } from 'store/slices/alert';

import { checkValue } from 'utils/validate';

export class EditItem {
  constructor({
    dispatch,
    setErrors,
    setSubmit,
    setProcess,
    setItemsValue,
    uploadImage,
    handleBack = () => {},
    handleClose = () => {},
  }) {
    this.dispatch = dispatch;
    this.handleBack = handleBack;
    this.handleClose = handleClose;
    this.setErrors = setErrors;
    this.setSubmit = setSubmit;
    this.setProcess = setProcess;
    this.setItemsValue = setItemsValue;
    this.uploadImage = uploadImage;
  }

  checkUpdateData(
    result,
    handleSuccess = () => {
      this.handleBack();
      this.handleClose();
    },
  ) {
    if (result.error) {
      let errorMessage =
        result.error?.data?.errorMessage ?? 'Something went wrong';
      if (result.error?.data?.errors?.length) {
        try {
          this.setErrors(
            result.error.data.errors.map((error) => {
              if (error.message) {
                errorMessage += `<p>${error.field}: ${error.message}</p>`;
              }
              return error.field;
            }) ?? [],
          );
        } catch {
          this.setErrors([]);
        }
      }
      this.dispatch(
        showAlert({
          type: 'error',
          text: errorMessage,
        }),
      );

      this.setSubmit(false);
      this.setProcess(false);
    } else {
      handleSuccess();
    }
  }

  handleChange({ name, value, errors, isCheckValue = true, ...props }) {
    this.setItemsValue((prevState) => {
      const newState = _.cloneDeep(prevState);
      _.set(newState, name, value);
      return newState;
    });
    if (isCheckValue) {
      checkValue({ value, name, setErrors: this.setErrors, errors, ...props });
    }
  }

  async updateImg(urls, itemsValue) {
    const errorsUpload = [];
    await Promise.all(
      urls.map(async (img) => {
        const value = _.get(itemsValue, img);
        if (typeof value !== 'string' && value?.name) {
          const formData = new FormData();
          formData.append('file', value, value.name);

          const result = await this.uploadImage(formData);
          if (result.data) {
            this.setItemsValue((prevState) => {
              const newState = _.cloneDeep(prevState);
              _.set(newState, img, result.data);
              return newState;
            });
          } else {
            errorsUpload.push(img);
          }
        }
      }),
    );
    return errorsUpload;
  }

  async uploadImages(updateImg) {
    this.setProcess(true);
    const updateImgErrors = await updateImg();
    if (updateImgErrors.length) {
      this.dispatch(showAlert({ type: 'error', text: 'Failed upload img' }));
    }
    this.setProcess(false);
    return updateImgErrors;
  }

  checkImages(fields, itemsValue) {
    let errorsFields = [];

    fields.map((name) => {
      const value = _.get(itemsValue, name);
      if (!checkValue({ value }) || _.isNull(value)) {
        errorsFields.push(name);
      }
    });

    return errorsFields;
  }

  checkText(fields, itemsValue) {
    let errorsFields = [];

    fields.map((name) => {
      if (!checkValue({ value: _.get(itemsValue, name) })) {
        errorsFields.push(name);
      }
    });

    return errorsFields;
  }

  checkNumeric(fields, itemsValue) {
    let errorsFields = [];

    fields.map((name) => {
      if (!checkValue({ value: _.get(itemsValue, name), type: 'number' })) {
        errorsFields.push(name);
      }
    });

    return errorsFields;
  }

  async validate({
    itemsValue,
    imageFields,
    textFields,
    numericFields,
    multipleFields,
    updateImg,
    errorsSubmit = [],
  }) {
    // check image fields
    if (imageFields) {
      errorsSubmit = [
        ...errorsSubmit,
        ...this.checkImages(imageFields, itemsValue),
      ];
    }

    // check text fields
    if (textFields) {
      errorsSubmit = [
        ...errorsSubmit,
        ...this.checkText(textFields, itemsValue),
      ];
    }

    // check numeric fields
    if (numericFields) {
      errorsSubmit = [
        ...errorsSubmit,
        ...this.checkNumeric(numericFields, itemsValue),
      ];
    }

    // check multiple selectors
    if (multipleFields) {
      multipleFields.map((name) => {
        if (_.get(itemsValue, name).length === 0) {
          errorsSubmit.push(name);
        }
      });
    }

    if (!errorsSubmit.length && updateImg) {
      const updateImgErrors = await this.uploadImages(updateImg);
      errorsSubmit = [...errorsSubmit, ...updateImgErrors];
    }

    this.setErrors(errorsSubmit);

    return errorsSubmit;
  }

  async handleSubmit(props) {
    if ((await this.validate(props)).length === 0) {
      this.setProcess(true);
      this.setSubmit(true);
    }
  }
}
