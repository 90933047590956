import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { EditItem } from 'utils/EditItem';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';

import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

import { langs } from 'components/config/langs';

const propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  translation: PropTypes.shape({}),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  langKey: PropTypes.string,
};

const Dialog = ({ fields, translation = {}, onSave, onCancel, langKey }) => {
  const dispatch = useDispatch();

  const [itemsValue, setItemsValue] = useState(translation);
  const [errors, setErrors] = useState([]);
  const [process, setProcess] = useState(false);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        setErrors,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value }) => {
      editItemClass.handleChange({
        name,
        errors,
        value,
      });
    },
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    const errors = await editItemClass.validate({
      itemsValue,
      textFields: [langKey, ...fields.map((item) => item.name)],
    });
    if (errors.length === 0) {
      onSave(itemsValue);
    }
  }, [itemsValue]);

  return (
    <MuiDialog maxWidth="md" open={true} fullWidth>
      <DialogTitle>
        {_.isEmpty(translation) ? 'Create Translations' : 'Update Translations'}
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {process && <FullPageLoader />}
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 1,
          }}
        >
          <Select
            name={langKey}
            label="Language"
            error={errors.includes(langKey)}
            value={itemsValue?.[langKey] ?? ''}
            handleChange={handleChange}
            options={langs}
            fullWidth
          />
          {fields.map(({ name, title, type }) => {
            return (
              <Text
                required
                key={name}
                name={name}
                label={title}
                onChange={({ target }) => handleChange(target)}
                inputProps={{ style: { resize: 'both' } }}
                error={errors.includes(name)}
                value={itemsValue?.[name] ?? ''}
                sx={{ gridColumn: '1 / 3' }}
                {...(type === 'textarea' && {
                  multiline: true,
                  minRows: 5,
                  maxRows: 10,
                })}
              />
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {_.isEmpty(translation) ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = propTypes;
export default Dialog;
