import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import {
  useGetNewsItemQuery,
  useUpdateNewsItemMutation,
} from 'store/api/api/clientAreaService/newsController';
import { useUploadImageMutation } from 'store/api/api/tournamentService/imageController';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import { KeyboardBackspace } from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import Editor from 'components/UI/Form/Editor';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import Columns from 'components/UI/Table/Columns';
import NotFound from 'components/pages/NotFound/NotFound';

import { POST_TYPES } from 'components/config/news';
import { PAGE_PATH } from 'components/config/pages';
import { DateFormat } from 'config/dates';

const initialValue = (editItem) =>
  editItem
    ? {
        ...editItem,
      }
    : {
        title: '',
        postType: POST_TYPES[0],
        description: '',
        shortDescription: '',
        publicationDate: '',
        postImage: null,
        backgroundImage: null,
      };

const NewsItem = () => {
  const dispatch = useDispatch();

  const { itemId } = useParams();
  const navigate = useNavigate();

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetNewsItemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const [updateNewsItem] = useUpdateNewsItemMutation();
  const [uploadImage] = useUploadImageMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.CLIENT_AREA_NEWS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
        uploadImage,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const result = await updateNewsItem({
      ...(editItem?.id && { id: editItem.id }),
      ...itemsValue,
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue]);

  const updateImg = useCallback(async () => {
    return await editItemClass.updateImg(
      ['postImage', 'backgroundImage'],
      itemsValue,
    );
  }, [itemsValue]);

  const handleChange = useCallback(
    ({ name, value }) => {
      editItemClass.handleChange({ name, value, errors });
    },
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    await editItemClass.handleSubmit({
      itemsValue,
      updateImg,
      imageFields: ['postImage', 'backgroundImage'],
      textFields: [
        'title',
        'shortDescription',
        'description',
        'publicationDate',
      ],
    });
  }, [itemsValue, errors]);

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (itemId === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [itemId, editItemStore]);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}

      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem
                  ? editItem.id
                    ? `Update "${editItem.title}"`
                    : `Copy by "${editItem.title}"`
                  : 'Create News'}
              </Box>
            </Box>
          </Box>

          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'scroll',
            }}
          >
            <Columns count={3}>
              <Select
                label="Type"
                name="postType"
                error={errors.includes('postType')}
                value={itemsValue.postType}
                handleChange={handleChange}
                options={POST_TYPES}
                required
              />

              <Text
                required
                label="Title"
                name="title"
                fullWidth
                onChange={({ target }) => handleChange(target)}
                error={errors.includes('title')}
                value={itemsValue.title}
                inputProps={{ maxLength: 256 }}
              />

              <DatePicker
                sx={{ width: '100%' }}
                label="Posted date"
                format={DateFormat}
                onChange={(newValue) => {
                  if (newValue && newValue.format() !== 'Invalid Date') {
                    handleChange({
                      name: 'publicationDate',
                      value: newValue.format('YYYY-MM-DD'),
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                    name: 'Start date',
                    error: !!errors.includes('publicationDate'),
                  },
                }}
                {...(editItem && {
                  defaultValue: dayjs(itemsValue.publicationDate),
                })}
              />
            </Columns>

            <Editor
              label="Short description"
              onChange={(value) =>
                handleChange({ name: 'shortDescription', value })
              }
              error={errors.includes('shortDescription')}
              value={itemsValue.shortDescription}
              inputProps={{ maxLength: 1000 }}
              modules={{
                toolbar: [['italic', 'underline'], ['link'], ['clean']],
              }}
              formats={['italic', 'underline', 'link']}
              height={100}
            />

            <Editor
              label="Description"
              onChange={(value) => handleChange({ name: 'description', value })}
              error={errors.includes('description')}
              value={itemsValue.description}
              inputProps={{ maxLength: 5000 }}
            />

            <Columns>
              <ImageUpload
                label="Image"
                value={itemsValue.postImage}
                handleChange={(value) =>
                  handleChange({ name: 'postImage', value })
                }
                hasError={errors.includes('postImage')}
              />
              <ImageUpload
                label="Background"
                value={itemsValue.backgroundImage}
                handleChange={(value) =>
                  handleChange({ name: 'backgroundImage', value })
                }
                hasError={errors.includes('backgroundImage')}
              />
            </Columns>
          </Stack>
          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button onClick={handleSubmit} variant="contained">
              {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default NewsItem;
