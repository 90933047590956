import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _find from 'lodash/find';
import _throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  ListItemText,
  Autocomplete as MuiAutocomplete,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.exact({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ]),
  ),
  multiple: PropTypes.bool,
  filterSelectedOptions: PropTypes.bool,
  selectFilteredOptions: PropTypes.bool,
  loading: PropTypes.bool,
};

const StyledAutocomplete = styled(MuiAutocomplete)`
  .MuiInputBase-formControl {
    max-width: min-content;
    min-width: 100%;
  }
`;

const SelectFilteredValue = 'selectFiltered';

const Autocomplete = ({
  name,
  label,
  value,
  error = false,
  handleChange,
  options = [],
  multiple = false,
  required = true,
  filterSelectedOptions = true,
  selectFilteredOptions = false,
  loading,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const formattedOptions = useMemo(
    () =>
      typeof options[0] === 'string'
        ? options.map((option) => ({ label: option, value: option }))
        : options,
    [options],
  );

  const filteredValues = useMemo(
    () => filteredOptions.map((option) => option.value),
    [filteredOptions],
  );

  const showSelectFilteredOption = useMemo(
    () => multiple && selectFilteredOptions,
    [multiple, selectFilteredOptions],
  );

  const allFilteredSelected = useMemo(
    () =>
      showSelectFilteredOption &&
      filteredValues.every((item) => value.includes(item)),
    [showSelectFilteredOption, filteredValues, value],
  );

  const actualOptions = useMemo(() => {
    const selectedOptions = filterSelectedOptions
      ? formattedOptions.filter((option) =>
          multiple ? value.includes(option.value) : value === option.value,
        )
      : [];

    const generalOptions = [
      ...new Set([...selectedOptions, ...filteredOptions]),
    ];

    if (
      !showSelectFilteredOption ||
      !filteredOptions.length ||
      (filterSelectedOptions && allFilteredSelected)
    )
      return generalOptions;

    return [
      { label: 'Select All Filtered', value: SelectFilteredValue },
      ...generalOptions,
    ];
  }, [
    filterSelectedOptions,
    formattedOptions,
    multiple,
    value,
    filteredOptions,
    showSelectFilteredOption,
    allFilteredSelected,
  ]);

  const changeInputValue = useCallback(
    _throttle(({ target: { value } }) => setInputValue(value), 500),
    [],
  );
  const clearInputValue = useCallback(() => setInputValue(''), []);

  const onChange = useCallback(
    (_, newValue) => {
      clearInputValue();

      if (multiple && newValue.at(-1)?.value === SelectFilteredValue) {
        handleChange({
          name,
          value: !allFilteredSelected
            ? [...new Set([...value, ...filteredValues])]
            : value.filter((item) => !filteredValues.includes(item)),
        });
        return;
      }

      handleChange({
        name,
        value: multiple
          ? newValue.map((v) => v?.value ?? v)
          : newValue?.value ?? newValue,
      });
    },
    [
      clearInputValue,
      multiple,
      handleChange,
      allFilteredSelected,
      value,
      filteredValues,
    ],
  );

  useEffect(() => {
    setFilteredOptions(
      formattedOptions.filter(({ label }) =>
        label.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    );
  }, [formattedOptions, inputValue]);

  return (
    <FormControl required={required} error={error} sx={{ width: '100%' }}>
      <StyledAutocomplete
        multiple={multiple}
        name={name}
        value={value}
        label={label ?? name}
        options={actualOptions}
        loading={loading}
        onChange={onChange}
        getOptionLabel={(option) => {
          if (typeof option === 'object') return option.label;
          return _find(options, { value: option })?.label ?? option.toString();
        }}
        getOptionKey={(option) => option.value}
        filterSelectedOptions={filterSelectedOptions}
        filterOptions={(option) =>
          option.value !== SelectFilteredValue && option
        }
        isOptionEqualToValue={(option, valueItem) =>
          option.value === valueItem.value || option.value === valueItem
        }
        renderInput={({ InputProps, ...params }) => (
          <TextField
            {...params}
            label={label ?? name}
            required={required}
            error={error}
            InputProps={{
              ...InputProps,
              onChange: changeInputValue,
              onBlur: clearInputValue,
              ...(loading && {
                endAdornment: (
                  <CircularProgress
                    size={18}
                    sx={{
                      position: 'absolute',
                      top: '18px',
                      right: '11px',
                    }}
                  />
                ),
              }),
            }}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const selectAllOption = option.value === SelectFilteredValue;
          return (
            <li {...props} key={option.value ?? option.label}>
              {!filterSelectedOptions && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={!selectAllOption ? selected : allFilteredSelected}
                />
              )}
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: selectAllOption ? 'bold' : 'normal',
                }}
              >
                {option.label}
              </ListItemText>
            </li>
          );
        }}
        {...rest}
      />
    </FormControl>
  );
};

Autocomplete.propTypes = propTypes;
export default Autocomplete;
